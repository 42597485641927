.modal-large {
  max-width: 70%;
}

.navbar-brand {
  height: 105px;
}
.navbar-brand img {
  height: 100%;
}

.icon-help-dark {
  cursor: pointer;
}

.av-loading-bullet {
  color: #2a93fc !important;
}

.loading .rc-switch,
.loading .rc-switch:after {
  transition: none !important;
}

.wfp-btn {
  color: #0374e6;
  border-color: #0374e6;
}

.wfp-btn--primary {
  background-color: #0374e6;
  border-color: #0374e6;
}

.wfp-btn:hover {
  background-color: #0374e6;
  border-color: #0374e6;
}

.wfp-btn--primary:hover {
  background-color: #0360bd;
  border-color: #0360bd;
}

.wfp-btn.active {
  background-color: #0360bd;
  border-color: #0360bd;
}
.wfp-btn.active:hover {
  background-color: #0360bd;
  border-color: #0360bd;
}
.wfp-btn.active:active {
  background-color: #0360bd;
  border-color: #0360bd;
}
.wfp-btn.active:focus {
  background-color: #0360bd;
  border-color: #0360bd;
}

.z-index-master {
  z-index: 1090;
}

a,
.cursor-pointer {
  cursor: pointer;
}

.replaced-input-file {
  position: relative;
}
.replaced-input-file input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.filters-form input[type=checkbox] {
  display: inline-block;
  width: 24px;
}
.filters-form input[type=text],
.filters-form input[type=number],
.filters-form .replaced-form-input {
  display: inline-block;
  width: calc(100% - 0.25em - 24px);
}
.filters-form select select,
.filters-form .row select {
  display: inline-block;
  width: calc(100% - 0.25em - 24px);
}
.filters-form .rdrMonthPicker,
.filters-form .rdrYearPicker {
  display: inline-block;
  width: calc(100% - 0.25em - 24px);
}
.filters-form .rdrMonthPicker select,
.filters-form .rdrYearPicker select {
  width: 100%;
}
.filters-form .rdrDefinedRangesWrapper .rdrInputRanges .rdrInputRange .rdrInputRangeInput {
  width: 35%;
}
.filters-form .rdrDefinedRangesWrapper .rdrInputRanges .rdrInputRange span {
  margin: 5px;
}

.dateFilterWithApply {
  border: 2px solid #999999;
}
.dateFilterWithApply .rdrDateRangePickerWrapper {
  border-bottom: 2px solid #999999;
}

.rdrInputRanges {
  visibility: hidden;
}

.wfp-header-ext {
  overflow: hidden;
  background-color: #2a93fc;
}
.wfp-header-ext .nav > li > a {
  color: #fff;
}
.wfp-header-ext .nav > li > a:hover, .wfp-header-ext .nav > li > a:focus {
  background-color: #2a93fc;
}

.wfp-form--stacked .invalid {
  margin-bottom: 0.25em !important;
}

.entitlement {
  padding: 5px;
  border-bottom: 1px dashed lightblue;
}
.entitlement:last-of-type {
  border-bottom: none;
}
.entitlement input.month {
  width: 70px;
}
.entitlement input.year {
  width: 100px;
}
.entitlement input.day {
  width: 70px;
}
.entitlement input.amount {
  width: 80px;
}
.entitlement select.form-control {
  background-image: none;
}
.entitlement label {
  min-width: 70px;
}

.wfp-form--stacked label {
  font-weight: normal;
}

.wfp-notice {
  font-weight: lighter;
  font-size: 10px;
}

.wfp-table--striped tr.failed td {
  background-color: #e08282;
}

table tbody tr.highlighted td {
  background-color: lightyellow !important;
}

.wfp-table--striped tr.pending-action td {
  background-color: #e0c636;
}

.wfp-table--striped tr.unknown td {
  background-color: rgba(151, 149, 149, 0.77);
}

.alert-button {
  float: right;
  font-weight: bold;
  margin-right: 10px;
  color: #000;
  filter: alpha(opacity=20);
  opacity: 0.6;
}

.negative-color {
  color: #ff5252;
}

.attention-color {
  color: #ffc000;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.indicator, .active-indicator, .blocked-indicator {
  text-transform: uppercase;
  font-size: 11px;
  vertical-align: 6px;
  padding: 5px;
  border-radius: 14px;
  margin-left: 7px;
}

.blocked-indicator {
  background-color: #ff0000;
  color: #ffffff;
}

.active-indicator {
  background-color: #177b57;
  color: #ffffff;
}

textarea,
input,
button {
  outline: none;
}

.wfp-menu .wfp-btn.active {
  outline: none;
  text-decoration: none;
}

.container-live {
  font-size: 11pt;
}

.container-live table td {
  text-align: center;
}

.container-live table th {
  text-align: center;
}

.shadowed-container {
  border-radius: 3px;
  box-shadow: 0px 3px 5px #bababa;
  border-color: #bababa;
  background: #f9fcff;
}

.wfp-account-table td,
.wfp-account-table th {
  text-align: right;
}
.wfp-account-table td:first-of-type,
.wfp-account-table th:first-of-type {
  white-space: nowrap;
  text-align: left;
}
.wfp-account-table .total-balance {
  font-weight: bold;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-item-align: center;
      align-self: center;
}
.navbar-nav > li {
  white-space: nowrap;
  float: right;
  height: 50%;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
  margin: 0 !important;
  padding: 0;
}
.navbar-nav > li:hover {
  border-bottom: 1px solid lightblue;
}
.navbar-nav > li a {
  border: none;
}
.navbar-nav > li.dropdown {
  position: relative;
}
.navbar-nav > li.dropdown .dropdown-menu {
  position: fixed;
  right: inherit;
  top: inherit;
}

.async-tasks-table thead tr th {
  text-align: center;
}
.async-tasks-table tbody tr td {
  padding: 10px 10px;
}
.async-tasks-table tbody tr.parent {
  background-color: #b2d8fe;
}
.async-tasks-table tbody tr.parent td {
  font-size: 16px;
}
.async-tasks-table tbody tr.parent:hover {
  background-color: #85c1fd;
}
.async-tasks-table tbody tr.child {
  background-color: white;
}
.async-tasks-table tbody tr.child:hover {
  background-color: #e8e8e8;
}
.async-tasks-table tbody tr.child td {
  font-size: 14px;
}
.async-tasks-table tbody tr.child td:first-child {
  padding-left: 40px;
  text-align: right;
}

.beneficiary-component-padding, .beneficiary-totals-component, .beneficiary-alert-component {
  padding: 25px;
}

.beneficiary-alert-debits-component {
  padding-bottom: 25px;
}

.modal-label {
  text-align: left;
  font-size: 80%;
}

.myNavbarItem {
  margin: 10px;
}

a:not([href]):not([tabindex]) {
  color: #036fdc;
}

label {
  font-weight: bold;
}

.BB-two-column-layout {
  column-count: 2;
}

@media (max-width: 800px) {
  .BB-two-column-layout {
    column-count: 1;
  }
}
.BDS-documents-page-header {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
}
.BDS-documents-page-header .header-container {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: start;
  gap: 1rem;
}
.BDS-documents-page-header a {
  border-bottom: none;
}

.BDS-upload-documents-form-container {
  display: -ms-grid;
  display: grid;
  gap: 1rem;
}
.BDS-upload-documents-form-container button {
  justify-self: center;
}
.BDS-upload-documents-form-container label {
  display: -ms-grid;
  display: grid;
  -ms-flex-align: center;
      align-items: center;
  -ms-grid-columns: 1fr 8fr;
      grid-template-columns: 1fr 8fr;
  gap: 0 15px;
}
.BDS-upload-documents-form-container label input {
  width: 100%;
}
.BDS-upload-documents-form-container label span {
  text-align: right;
}
.BDS-upload-documents-form-container .progress {
  width: 50%;
  justify-self: center;
}
.BDS-upload-documents-form-container .progress [role=progressbar] {
  width: 100%;
}

.BDS-document-section-wrapper.active > * {
  pointer-events: none;
}
.BDS-document-section-wrapper svg {
  opacity: 0;
}
.BDS-document-section-wrapper:hover h5 svg {
  opacity: 1;
}
.BDS-document-section-wrapper .BDS-document-list-item-wrapper {
  position: relative;
  display: -ms-inline-grid;
  display: inline-grid;
  grid-auto-flow: column;
  -ms-flex-pack: start;
      justify-content: start;
  gap: 0.5rem;
}
.BDS-document-section-wrapper .BDS-document-list-item-wrapper .dot {
  width: 0.5rem;
  height: 0.5rem;
  background: red;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  border-radius: 100%;
}
.BDS-document-section-wrapper .BDS-document-list-item-wrapper:hover svg {
  opacity: 1;
}
.BDS-document-section-wrapper .BDS-document-list-item-wrapper > button {
  all: unset;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.BDS-document-section-wrapper button svg {
  width: 1.25rem;
  fill: #a8d3fe;
  will-change: fill;
  transition: fill 130ms ease-in;
}
.BDS-document-section-wrapper button svg:hover {
  fill: #2a93fc;
}
.BDS-document-section-wrapper > h5 {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: center;
      align-items: center;
  gap: 0.5rem;
}
.BDS-document-section-wrapper > h5 button {
  all: unset;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
}
.BDS-document-section-wrapper > h5 button svg {
  width: 1.25rem;
  fill: #a8d3fe;
  will-change: fill;
  transition: fill 130ms ease-in;
}
.BDS-document-section-wrapper > h5 button svg:hover {
  fill: #2a93fc;
}

.BB-column-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.BB-select {
  border: 1px solid tomato;
  width: 100%;
}

.wfp-wrapper.BB-wrapper--wide {
  width: 100vw;
}

@media screen and (min-width: 80em) {
  .wfp-wrapper.BB-wrapper--wide {
    max-width: 100vw;
  }
}
.react-select-container input[type=text] {
  box-shadow: unset;
}